import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CallbackPage from "./Pages/callbackPage/CallbackPage";
import LandingPage from "./Pages/landingPage/LandingPage";
import WidgetPage from "./Pages/widgetPage/WidgetPage";
import './App.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/redirect" element={<WidgetPage />} /> */}
          <Route path="/widget" element={<WidgetPage />} />
          {/* <Route path="/widget_page" element={<WidgetPage />} /> */}
          <Route path="/callback_page" element={<CallbackPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
