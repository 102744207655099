import { useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';

const LandingPage = () => {
    const [searchParamData] = useSearchParams();

    const clickFunction = async (action, serviceid) => {
        let user_device_id = searchParamData.get('device_id');
        localStorage.setItem('device_id', user_device_id);

        let token = searchParamData.get('token');

        localStorage.setItem('token', token);
        var callbackUrl = `${window.location.protocol}//${window.location.host}/callback_page`;

        // Add more parameters according to Widget documentation.
        window.location.assign("/widget?" +
            encodeURIComponent("token=" + token + "&serviceid=" + serviceid + "&callbackUrl=" + callbackUrl));
        // encodeURIComponent("token=" + token + "&action=" + action + "&serviceid=" + serviceid + "&callbackUrl=" + callbackUrl));
    }

    useEffect(() => {
        async function data() {

            // for register flow (for first/subscription purchase time users)
            clickFunction('signup', '1288')

            // for login flow 
            // clickFunction('signin', '1288')
        }
        data();
        // eslint-disable-next-line
    }, [])

    return (
        < >
        </>
    )
}

export default LandingPage
