import React from 'react';
import widgetStyle from './widget.module.css';

const WidgetPage = () => {

  return (
    <>
      <div className={`${widgetStyle.container} content_div widget_label`}>
      </div>

      <div>
        <img
          className={widgetStyle.musicGroupVector}
          src='./assets/Images/GroupNew.svg' alt=''
        />
      </div>
    </>
  )
}

export default WidgetPage
