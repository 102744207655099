// import React, { useState, useEffect } from 'react';
import React, { useEffect } from 'react';
import GlobalURL from '../../BaseURL';
import axios from 'axios';

const CallbackPage = () => {

  const handleSubscriptionDetails = async (details) => {
    try {
      const response = await axios.post(`${GlobalURL}/api/widget/getSubscriptionDetails`,
        details,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      // Handle response data as needed
      console.log("subscription response data: ", response.data);
    } catch (error) {
      
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const divRef = document.getElementById('register-container');
    divRef.style.display = 'none';

    const queryString = decodeURIComponent(window.location.search);
    const urlParams = new URLSearchParams(queryString);

    let device_id = localStorage.getItem("device_id") || "UDA_NA_404";
    let action = urlParams.get("action");
    let result = urlParams.get("result");
    let msisdn = urlParams.get("msisdn");
    let subscription_id = urlParams.get("subscriptionId");
    let error_code = urlParams.get("errorCode");
    let transaction_id = urlParams.get("transactionId");
    let status = urlParams.get("status");
    let service_id = urlParams.get("serviceid");
    let advertising_id = urlParams.get("advertisingid");
    let subscribed_offer = urlParams.get("subscribedOffer");

    const subscriptionDetails = {
      device_id,
      action,
      result,
      msisdn,
      subscription_id,
      error_code,
      transaction_id,
      status,
      service_id,
      advertising_id,
      subscribed_offer
    }

    handleSubscriptionDetails(subscriptionDetails);

    // eslint-disable-next-line 
  }, [])


  return (
    <div>

    </div>
  )
}

export default CallbackPage
